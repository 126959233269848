import { sort } from "fast-sort";

let listOfItems = null;
let selectedItem = null;
let dropdown = null;
let selectedProject = null;
let lang = "pl";
let globalStartDate = null;
let globalLeadsCounter = 0;
let preEndDateGlobal = null;

const translations = {
  en: {
    "welcome-text": "Welcome to our website!",
    "contact-text": "Contact us",
    "client-text": "Client: ",
    "account-manager-text": "Customer Success Manager: ",
    "welcome-text": "Welcome to your dashboard",
    "campaign-summary-text": "Campaign summary",
    "campaign-summary-opened-text": "Opened",
    "campaign-summary-responses-text": "Responses",
    "campaign-summary-interest-text": "Interest level",
    "project-summary-current-date-text": "Current as of:",
    "project-summary-text": "Project summary",
    "project-summary-positive-leads-text": "Number of leads:",
    "project-summary-negative-leads-text": "Number of maybe later:",
    "project-summary-start-date-text": "Start date:",
    "project-summary-end-date-text": "Planned project end:",
    "current-campaigns-text": "Current campaigns",
    "archived-campaigns-text": "Archived",
    "sorting-text": "Sort by:",
    "sorting-choose-text": "choose",
    "sorting-date-text_desc": "Date ↓",
    "sorting-date-text_asc": "Date ↑",
    "sorting-name-text_desc": "Name ↓",
    "sorting-name-text_asc": "Name ↑",
    "leads-panel-empty-text":
      "Please select a campaign from the panel on the left",
    "campaign-text": "Campaign:",
    "leads-panel-no-leads-text": "No leads to display",
  },
  pl: {
    "welcome-text": "Witaj na naszej stronie!",
    "contact-text": "Skontaktuj się z nami",
    "client-text": "Klient: ",
    "account-manager-text": "Opiekun: ",
    "welcome-text": "Witaj w swoim panelu",
    "campaign-summary-text": "Podsumowanie kampanii",
    "campaign-summary-opened-text": "Otwarte",
    "campaign-summary-responses-text": "Odpowiedzi",
    "campaign-summary-interest-text": "Poziom zainteresowania",
    "project-summary-current-date-text": "Aktualne z dnia:",
    "project-summary-text": "Podsumowanie projektu",
    "project-summary-positive-leads-text": "Liczba leadów:",
    "project-summary-negative-leads-text": "Liczba maybe later:",
    "project-summary-start-date-text": "Start projektu:",
    "project-summary-end-date-text": "Planowany czas końca projektu:",
    "current-campaigns-text": "Obecne kampanie",
    "archived-campaigns-text": "Archiwum",
    "sorting-text": "Sortuj według:",
    "sorting-choose-text": "wybierz",
    "sorting-date-text_desc": "Data ↓",
    "sorting-date-text_asc": "Data ↑",
    "sorting-name-text_desc": "Nazwa ↓",
    "sorting-name-text_asc": "Nazwa ↑",
    "leads-panel-empty-text":
      "Proszę wybrać kampanię z panelu po lewej stronie",
    "campaign-text": "Kampania:",
    "leads-panel-no-leads-text": "Brak leadów do wyświetlenia",
  },
};

document.addEventListener("DOMContentLoaded", async () => {
  const loadingScreen = document.getElementById("loadingScreen");
  const contentWebPage = document.querySelector(".main-container--js");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  if (!id) {
    window.location.href = "/error-page.html";
  }

  document.querySelectorAll(".language-button--js").forEach((button) => {
    button.addEventListener("click", function () {
      if (lang !== this.getAttribute("data-lang")) {
        lang = this.getAttribute("data-lang");
        const btn = document.getElementById("language-button");

        const projectEndNumber = document.querySelector("#project-end");
        const leadsCounterElement = document.querySelector("#leads-counter");
        const projectStartNumber = document.querySelector("#project-start");

        const months = [
          "styczeń",
          "luty",
          "marzec",
          "kwiecień",
          "maj",
          "czerwiec",
          "lipiec",
          "sierpień",
          "wrzesień",
          "październik",
          "listopad",
          "grudzień",
        ];
        const monthsEn = [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ];

        if (lang === "pl") {
          btn.style.left = "0px";
          if (
            projectEndNumber.innerText &&
            projectEndNumber.innerText !== "-"
          ) {
            const dateArray = projectEndNumber.innerText.split(" ");
            const month = dateArray[dateArray.length - 2];
            const year = dateArray[dateArray.length - 1];
            const indexOf = monthsEn.indexOf(month);
            projectEndNumber.innerText = preEndDateGlobal
              ? preEndDateGlobal + " " + months[indexOf] + " " + year
              : months[indexOf] + " " + year;
          }
          if (leadsCounterElement.innerText) {
            leadsCounterElement.innerText = `Wyświetlono ${globalLeadsCounter} z ${globalLeadsCounter} wiadomości`;
          }
          if (
            projectStartNumber.innerText &&
            projectStartNumber.innerText !== "-"
          ) {
            const month = projectStartNumber.innerText.split(" ")[0];
            const year = projectStartNumber.innerText.split(" ")[1];
            const indexOf = monthsEn.indexOf(month);
            projectStartNumber.innerText = months[indexOf] + " " + year;
          }
        } else {
          btn.style.left = "32px";
          if (
            projectEndNumber.innerText &&
            projectEndNumber.innerText !== "-"
          ) {
            const dateArray = projectEndNumber.innerText.split(" ");
            const month = dateArray[dateArray.length - 2];
            const year = dateArray[dateArray.length - 1];
            const indexOf = months.indexOf(month);
            projectEndNumber.innerText = preEndDateGlobal
              ? preEndDateGlobal + " " + monthsEn[indexOf] + " " + year
              : monthsEn[indexOf] + " " + year;
          }
          if (leadsCounterElement.innerText) {
            leadsCounterElement.innerText = `Shown ${globalLeadsCounter} of ${globalLeadsCounter} messages`;
          }
          if (
            projectStartNumber.innerText &&
            projectStartNumber.innerText !== "-"
          ) {
            const month = projectStartNumber.innerText.split(" ")[0];
            const year = projectStartNumber.innerText.split(" ")[1];
            const indexOf = months.indexOf(month);
            projectStartNumber.innerText = monthsEn[indexOf] + " " + year;
          }
        }
        changeLanguage(lang);
      }
    });
  });

  const campaignSummaryElement = document.querySelector("#campaign-summary");
  const welcomeElement = document.querySelector("#welcome-text");
  const summaryWrap = document.querySelector("#summary-wrapp-id");
  const tmpSummaryWrap = document.querySelector(".image-maybelater--js");
  campaignSummaryElement.style.display = "none";
  welcomeElement.style.display = "block";
  summaryWrap.style.display = "none";
  tmpSummaryWrap.style.display = "none";

  const leadsCounterElement = document.querySelector("#leads-counter");
  leadsCounterElement.style.visibility = "hidden";

  const tabsMain = document.querySelector(
    "#w-node-_33dc3b9e-7c5d-9a0b-262f-2142c39b5972-1db0ed5f"
  );
  tabsMain.style.display = "none";
  const sortingButton = document.querySelector("#sorting-button");
  sortingButton.style.visibility = "hidden";

  try {
    const response = await fetchData(id);

    // if (response) {
    //   loadingScreen.classList.add('hidden');
    //   contentWebPage.style.display = 'block';
    //   console.log(response.language);
    // }

    const clientName = document.querySelector("#client-name");
    const managerName = document.querySelector("#manager-name");

    clientName.innerText = response.name;
    managerName.innerText = response.manager;

    const currentProjectsAnchor = document.querySelector("#current-projects");
    const archivedProjectsAnchor = document.querySelector("#archived-projects");

    response.projects.forEach((project) => {
      const campaignsInProject = response.campaigns.filter(
        (campaign) => campaign.id_project[0] === project.id_project
      );

      if (campaignsInProject.length === 0) return;

      if (!project.flag_archive) {
        const activeCampaigns = campaignsInProject.filter(
          (campaign) => campaign.id_project[0] === project.id_project
        );
        const activeCampaignsSorted = sort(activeCampaigns).desc(
          (campaign) => campaign.campaign_created
        );

        const projectList = createProjectElement(
          project,
          activeCampaignsSorted,
          currentProjectsAnchor,
          tabsMain,
          campaignSummaryElement,
          welcomeElement,
          leadsCounterElement,
          response,
          summaryWrap,
          tmpSummaryWrap,
          sortingButton
        );

        const leadsMaybelater = response.leads.filter(
          (lead) =>
            lead.flag_maybelater && lead.id_project[0] === project.id_project
        );

        if (leadsMaybelater.length !== 0) {
          const maybeLaterElement = document.createElement("li");
          maybeLaterElement.id = `maybelater-${project.id_project}`;
          maybeLaterElement.className =
            "flex cursor-pointer justify-start items-center mt-4 p-4 bg-white shadow-lg border-l-8 border-lang-bg-btn border-solid rounded-xl";
          const maybeLaterTitle = document.createElement("div");
          maybeLaterTitle.innerText = "Maybe later";
          maybeLaterElement.appendChild(maybeLaterTitle);
          currentProjectsAnchor.appendChild(maybeLaterElement);
          projectList.appendChild(maybeLaterElement);
        }
      } else {
        const archivedCampaigns = campaignsInProject
          .filter((campaign) => campaign.id_project[0] === project.id_project)
          .sort((a, b) => a - b);
        const archivedCampaignsSorted = sort(archivedCampaigns).desc(
          (campaign) => campaign.campaign_created
        );

        const projectList = createProjectElement(
          project,
          archivedCampaignsSorted,
          archivedProjectsAnchor,
          tabsMain,
          campaignSummaryElement,
          welcomeElement,
          leadsCounterElement,
          response,
          summaryWrap,
          tmpSummaryWrap,
          sortingButton
        );

        const leadsMaybelater = response.leads.filter(
          (lead) =>
            lead.flag_maybelater && lead.id_project[0] === project.id_project
        );

        if (leadsMaybelater.length !== 0) {
          const maybeLaterElement = document.createElement("li");
          maybeLaterElement.id = `maybelater-${project.id_project}`;
          maybeLaterElement.className =
            "flex cursor-pointer justify-start items-center mt-4 p-4 bg-white shadow-lg border-l-8 border-lang-bg-btn border-solid rounded-xl";
          const maybeLaterTitle = document.createElement("div");
          maybeLaterTitle.innerText = "Maybe later";
          maybeLaterElement.appendChild(maybeLaterTitle);
          archivedProjectsAnchor.appendChild(maybeLaterElement);
          projectList.appendChild(maybeLaterElement);
        }
      }
    });

    console.log(archivedProjectsAnchor.childElementCount);
    if (archivedProjectsAnchor.childElementCount <= 1) {
      archivedProjectsAnchor.style.display = "none";
    }

    const buttons = document.querySelectorAll(".html-embed.w-embed");

    buttons.forEach((button) => {
      button.addEventListener("click", (event) => {
        event.stopPropagation();
        handleDropdownToggle(
          button,
          tabsMain,
          campaignSummaryElement,
          welcomeElement,
          leadsCounterElement,
          response,
          summaryWrap,
          tmpSummaryWrap,
          sortingButton
        );
      });
    });

    const languagesSelection = document.querySelector(".languages--js");
    languagesSelection.addEventListener("change", (event) => {
      sortElements(event.target.value);
    });

    if (response) {
      document.querySelectorAll(".language-button--js").forEach((button) => {
        if (
          response.language &&
          lang === "pl" &&
          button.getAttribute("data-lang") === "en"
        ) {
          console.log(button);
          button.click();
        }
      });
      loadingScreen.classList.add("hidden");
      contentWebPage.style.display = "block";
    }
  } catch (error) {
    console.log(error);
    window.location.href = "./error-page.html";
  }
});

async function fetchData(id) {
  // const url = `https://tsp9vux0z0.execute-api.eu-north-1.amazonaws.com/default/getDataAT?id=${id}`;
  const url = `api_proxy.php?id=${id}`;

  const myInit = {
    method: "GET",
  };

  try {
    const response = await fetch(url, myInit);

    if (!response.ok) {
      throw new Error("Wystąpił problem z żądaniem" + response.status);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

function stringToHTML(str) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  return doc.body.firstChild;
}

function resetAllStats() {
  const tabsMain = document.querySelector(
    "#w-node-_33dc3b9e-7c5d-9a0b-262f-2142c39b5972-1db0ed5f"
  );
  tabsMain.innerHTML = "";
  tabsMain.style.display = "none";
  const sortingButton = document.querySelector("#sorting-button");
  sortingButton.style.visibility = "hidden";

  const tempTabInfo = document.querySelector(
    `#w-node-d82b670a-8a94-b267-a455-5c086fd236b7-1db0ed5f`
  );
  tempTabInfo.style.display = "grid";

  const leadsCounterElement = document.querySelector("#leads-counter");
  leadsCounterElement.style.visibility = "hidden";

  const sortingText = document.querySelector(".languages--js");
  sortingText.selectedIndex = 0;

  const campaignSummaryElement = document.querySelector("#campaign-summary");
  const welcomeElement = document.querySelector("#welcome-text");
  const summaryWrap = document.querySelector("#summary-wrapp-id");
  const tmpSummaryWrap = document.querySelector(".image-maybelater--js");
  campaignSummaryElement.style.display = "none";
  welcomeElement.style.display = "block";
  summaryWrap.style.display = "none";
  tmpSummaryWrap.style.display = "none";

  const projectLeadsNumberPositive = document.querySelector(
    "#project-leads-positive"
  );
  const projectLeadsNumberNegative = document.querySelector(
    "#project-leads-negative"
  );
  const projectStartNumber = document.querySelector("#project-start");
  const projectEndNumber = document.querySelector("#project-end");

  projectLeadsNumberPositive.innerText = "-";
  projectLeadsNumberNegative.innerText = "-";
  projectStartNumber.innerText = "-";
  projectEndNumber.innerText = "-";

  const opened = document.querySelector("#opened-emails");
  const responded = document.querySelector("#responded-emails");
  const interested = document.querySelector("#interested-leads");
  const maybeLater = document.querySelector("#maybe-later-leads");

  opened.innerText = "-";
  responded.innerText = "-";
  interested.innerText = "-";
  maybeLater.innerText = "-";
}

function getProjectDateUpdate() {
  const now = new Date();

  const year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  let hour = now.getHours();
  let minute = now.getMinutes();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hour = hour < 10 ? "0" + hour : hour;
  minute = minute < 10 ? "0" + minute : minute;

  return `${year}-${month}-${day} ${hour}:${minute}`;
}

function createButton(id) {
  const buttonTemplate = `
        <button id="button-left-${id}" class="h-5 flex justify-center items-center bg-white text-black origin-center rotate-0 transition ease-in-out duration-300">
            <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                <path d="M7.41 18.41L6 17L12 11L18 17L16.59 18.41L12 13.83L7.41 18.41ZM7.41 12.41L6 11L12 5L18 11L16.59 12.41L12 7.83L7.41 12.41Z" fill="currentColor"></path>
            </svg>
        </button>`;
  return stringToHTML(buttonTemplate);
}

function createProjectElement(
  project,
  campaigns,
  anchor,
  tabsMain,
  campaignSummaryElement,
  welcomeElement,
  leadsCounterElement,
  response,
  summaryWrap,
  tmpSummaryWrap,
  sortingButton
) {
  const projectElement = document.createElement("div");
  projectElement.className = "mt-4 mb-4";
  const projectHeader = document.createElement("div");
  projectHeader.id = `div-left-${project.id_project}`;
  projectHeader.className =
    "flex justify-between items-center p-4 cursor-pointer bg-white rounded-xl shadow-box-shadow";
  const projectTitle = document.createElement("div");
  projectTitle.innerText = project.title;

  const buttonNode = createButton(project.id_project);
  projectHeader.appendChild(projectTitle);
  projectHeader.appendChild(buttonNode);
  projectHeader.addEventListener("click", () => {
    handleDropdownToggle(
      buttonNode,
      tabsMain,
      campaignSummaryElement,
      welcomeElement,
      leadsCounterElement,
      response,
      summaryWrap,
      tmpSummaryWrap,
      sortingButton
    );
  });

  const projectList = document.createElement("ul");
  projectList.className =
    "max-h-0 overflow-hidden ease-in-out duration-500 p-0 ml-4";
  projectList.id = `dropdown-content-button-left-${project.id_project}`;

  campaigns.forEach((campaign) => {
    const campaignElement = document.createElement("li");
    campaignElement.id = `campaign-${campaign.id_campaign}`;
    campaignElement.className =
      "flex cursor-pointer justify-start items-center mt-4 p-4 bg-white shadow-lg border-l-8 border-lang-bg-btn border-solid rounded-xl";
    const campaignTitle = document.createElement("div");
    // campaignTitle.classList = 'truncate';
    campaignTitle.innerText = campaign.title;
    campaignElement.appendChild(campaignTitle);
    projectList.appendChild(campaignElement);
  });

  projectElement.appendChild(projectHeader);
  projectElement.appendChild(projectList);

  anchor.appendChild(projectElement);

  return projectList;
}

function handleDropdownToggle(
  button,
  tabsMain,
  campaignSummaryElement,
  welcomeElement,
  leadsCounterElement,
  response,
  summaryWrap,
  tmpSummaryWrap,
  sortingButton
) {
  if (selectedProject && selectedProject !== button) {
    const oldDropdownId = `dropdown-content-${selectedProject.id}`;
    const oldDropdown = document.querySelector(`#${oldDropdownId}`);
    if (oldDropdown) {
      oldDropdown.classList.remove("max-h-[3000]");
    }
    selectedProject.classList.remove("rotate-180");
  }

  if (selectedItem) {
    selectedItem.classList.add("bg-white");
    selectedItem.classList.remove("bg-lang-bg-btn");
    selectedItem.classList.remove("text-white");
  }

  resetAllStats();

  button.classList.toggle("rotate-180");

  const buttonId = button.id;
  dropdown = document.querySelector(`#dropdown-content-${buttonId}`);

  if (dropdown.classList.contains("max-h-[3000]")) {
    dropdown.classList.remove("max-h-[3000]");
    button.classList.remove("rotate-180");
    selectedProject = null;
  } else {
    dropdown.classList.add("max-h-[3000]");
    button.classList.add("rotate-180");
    selectedProject = button;
    const dropdownId = dropdown.id;
    listOfItems = dropdown.querySelectorAll(`#${dropdownId} li`);
    if (listOfItems.length !== 0) {
      listOfItems.forEach((item) => {
        item.addEventListener("click", () => {
          if (selectedItem) {
            selectedItem.classList.add("bg-white");
            selectedItem.classList.remove("bg-lang-bg-btn");
            selectedItem.classList.remove("text-white");
          }
          item.classList.remove("bg-white");
          item.classList.add("bg-lang-bg-btn");
          item.classList.add("text-white");
          selectedItem = item;

          const sortingText = document.querySelector(".languages--js");
          sortingText.selectedIndex = 0;

          tabsMain.style.display = "none";
          tabsMain.innerHTML = "";
          sortingButton.style.visibility = "hidden";
          welcomeElement.style.display = "none";
          campaignSummaryElement.style.display = "block";
          summaryWrap.style.display = "block";

          const heading = document.querySelector(`.header-title--js`);
          heading.innerText = item.innerText;

          const projectId = parseInt(dropdownId.split("-")[4]);

          const projectLeadsPositive = response.campaigns.reduce(
            (acc, campaign) => {
              if (campaign.id_project[0] === projectId) {
                acc += campaign.reaction_positive;
              }
              return acc;
            },
            0
          );

          const projectLeadsNegative = response.campaigns.reduce(
            (acc, campaign) => {
              if (campaign.id_project[0] === projectId) {
                acc += campaign.reaction_negative;
              }
              return acc;
            },
            0
          );

          const projectEndsText = document.querySelector("#project-end-text");

          const projectIsArchived = response.projects.find(
            (project) => project.id_project === projectId
          ).flag_archive;

          if (lang === "pl") {
            projectEndsText.innerText = projectIsArchived
              ? "Zakończenie projektu:"
              : "Planowany czas końca projektu:";
          } else {
            projectEndsText.innerText = projectIsArchived
              ? "Project end:"
              : "Planned project end:";
          }

          const projectStart = response.projects.find(
            (project) => project.id_project === projectId
          ).date_start;
          const projectEnd = response.projects.find(
            (project) => project.id_project === projectId
          ).date_end;
          const projectPreEnd = response.projects.find(
            (project) => project.id_project === projectId
          )["pre-date"];

          const dataStart = new Date(projectStart);
          const dataEnd = projectEnd !== null ? new Date(projectEnd) : null;

          const months = [
            "styczeń",
            "luty",
            "marzec",
            "kwiecień",
            "maj",
            "czerwiec",
            "lipiec",
            "sierpień",
            "wrzesień",
            "październik",
            "listopad",
            "grudzień",
          ];
          const monthsEn = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december",
          ];

          const monthStart =
            lang === "pl"
              ? months[dataStart.getMonth()]
              : monthsEn[dataStart.getMonth()];
          const monthEnd =
            dataEnd !== null
              ? lang === "pl"
                ? months[dataEnd.getMonth()]
                : monthsEn[dataEnd.getMonth()]
              : null;
          const yearStart = dataStart.getFullYear();
          const yearEnd = dataEnd !== null ? dataEnd.getFullYear() : null;

          const dateProjectStart = `${monthStart} ${yearStart}`;
          const dateProjectEnd =
            monthEnd && yearEnd ? `${monthEnd} ${yearEnd}` : "brak";

          const dateUpdate = getProjectDateUpdate();

          const projectDateUpdate = document.querySelector(
            "#project-summary-update"
          );
          const projectLeadsNumberPositive = document.querySelector(
            "#project-leads-positive"
          );
          const projectLeadsNumberNegative = document.querySelector(
            "#project-leads-negative"
          );
          const projectStartNumber = document.querySelector("#project-start");
          const projectEndNumber = document.querySelector("#project-end");

          projectDateUpdate.innerHTML = dateUpdate;
          projectLeadsNumberPositive.innerText = projectLeadsPositive;
          projectLeadsNumberNegative.innerText = projectLeadsNegative;
          projectStartNumber.innerText = dateProjectStart;
          globalStartDate = dateProjectStart;

          if (dateProjectEnd !== "brak") {
            const projectEndCell =
              document.querySelectorAll(".project-end--js");
            projectEndNumber.style.display = "block";
            projectEndCell.forEach((el) => (el.style.visibility = "visible"));
            projectEndNumber.innerText = projectPreEnd
              ? `${projectPreEnd} ${dateProjectEnd}`
              : dateProjectEnd;
            preEndDateGlobal = projectPreEnd;
          } else if (dateProjectEnd === "brak") {
            const projectEndCell =
              document.querySelectorAll(".project-end--js");
            projectEndNumber.style.display = "none";
            projectEndCell.forEach((el) => (el.style.visibility = "hidden"));
          }

          const campaignId = parseInt(item.id.split("-")[1]);

          if (!item.id.includes("maybelater")) {
            summaryWrap.style.display = "block";
            tmpSummaryWrap.style.display = "none";

            const opened = document.querySelector("#opened-emails");
            const responded = document.querySelector("#responded-emails");
            const interested = document.querySelector("#interested-leads");
            const maybeLater = document.querySelector("#maybe-later-leads");

            const openedMails = (
              response.campaigns.find(
                (campaign) => campaign.id_campaign === campaignId
              ).mail_opened * 100
            ).toFixed(1);
            const answeredMails = (
              response.campaigns.find(
                (campaign) => campaign.id_campaign === campaignId
              ).mail_answers * 100
            ).toFixed(1);
            const interestedLeads = response.campaigns.find(
              (campaign) => campaign.id_campaign === campaignId
            ).reaction_positive;
            const maybeLaterLeads = response.campaigns.find(
              (campaign) => campaign.id_campaign === campaignId
            ).reaction_negative;

            opened.innerText = `${openedMails}%`;
            responded.innerText = `${answeredMails}%`;
            interested.innerText = interestedLeads;
            maybeLater.innerText = maybeLaterLeads;
          } else {
            summaryWrap.style.display = "none";
            tmpSummaryWrap.style.display = "flex";
          }

          let leadsCounter = 0;
          const tempTabInfo = document.querySelector(
            `#w-node-d82b670a-8a94-b267-a455-5c086fd236b7-1db0ed5f`
          );

          if (!item.id.includes("maybelater")) {
            const leadsList = response.leads.filter(
              (lead) =>
                lead.id_campaign[0] === campaignId && !lead.flag_maybelater
            );

            let currentOpenHeader = null;
            tempTabInfo.style.display = "none";

            if (leadsList.length !== 0) {
              tabsMain.style.display = "flex";
              sortingButton.style.visibility = "visible";

              leadsList.forEach((lead) => {
                leadsCounter++;
                const dataLead = new Date(lead.date);

                const dayLead = String(dataLead.getDate()).padStart(2, "0");
                const monthLead = String(dataLead.getMonth() + 1).padStart(
                  2,
                  "0"
                );
                const yearLead = dataLead.getFullYear();

                const dataLeadFormat = `${dayLead}/${monthLead}/${yearLead}`;

                const leadElement = `<li id="lead-${
                  lead.id_lead + lead.id_campaign
                }" class="flex flex-col items-stretch text-sm bg-white">
                                            <div class="rounded-2xl">
                                              <div class="flex flex-row justify-between items-center p-4 shadow-box-shadow rounded-2xl text-black focus:bg-focus gap-x-2 lead--js">
                                                <div class="w-3/12 text-sm name--js">${
                                                  lead.sender_name
                                                }</div>
                                                <div class="w-3/12 text-sm date--js">${dataLeadFormat}</div>
                                                <div class="w-6/12 text-sm comapny--js">${
                                                  lead.sender_company
                                                }</div>
                                                <button id="button-center-${
                                                  lead.id_lead +
                                                  lead.id_campaign
                                                }" class="h-5 flex justify-center items-center text-black origin-center rotate-0 transition ease-in-out duration-300">
                                                  <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                                                    <path d="M7.41 18.41L6 17L12 11L18 17L16.59 18.41L12 13.83L7.41 18.41ZM7.41 12.41L6 11L12 5L18 11L16.59 12.41L12 7.83L7.41 12.41Z" fill="currentColor"></path>
                                                  </svg>
                                                </button>
                                              </div>
                                              <div id="open-tab-content-button-center-${
                                                lead.id_lead + lead.id_campaign
                                              }" class="max-h-0 overflow-hidden border-none ease-in-out duration-300">
                                                <div class="p-4 shadow-box-shadow border border-solid rounded-b-xl email-content--js"></div>
                                                <div id="contacts-buttons-button-center-${
                                                  lead.id_lead +
                                                  lead.id_campaign
                                                }" class="mt-2 p-4 flex flex-row gap-x-3 items-center border-black border-b">
                                                  <div class="max-w-[25%] p-4 overflow-hidden whitespace-nowrap text-ellipsis border borde-solid border-border-container rounded-lg">
                                                    <a href="mailto:${
                                                      lead.sender_email
                                                    }" title="${
                  lead.sender_email
                }" class="text-black text-sm no-underline">${
                  lead.sender_email
                }</a>
                                                  </div>
                                                  <div class="max-w-[25%] p-4 overflow-hidden whitespace-nowrap text-ellipsis border borde-solid border-border-container rounded-lg">
                                                    <span class="text-black text-sm no-underline" title="${
                                                      lead.sender_position
                                                    }">${
                  lead.sender_position ? lead.sender_position : "Pan/Pani"
                }</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>`;

                const leadElementNode = stringToHTML(leadElement);
                tabsMain.appendChild(leadElementNode);

                const preElement = document.createElement("pre");
                preElement.className =
                  "text-xs word-break-all whitespace-pre-wrap break-words font-sans";
                preElement.innerHTML = lead.content;
                document
                  .querySelector(
                    `#open-tab-content-button-center-${
                      lead.id_lead + lead.id_campaign
                    } > .email-content--js`
                  )
                  .appendChild(preElement);

                const tabHead = document.querySelector(
                  `#lead-${
                    lead.id_lead + lead.id_campaign
                  } > div > div.lead--js`
                );
                tabHead.addEventListener("click", function () {
                  const buttonCenter = this.querySelector("button");

                  if (currentOpenHeader && currentOpenHeader !== this) {
                    const prevButton =
                      currentOpenHeader.querySelector("button");
                    prevButton.click();
                  }

                  buttonCenter.classList.toggle("rotate-180");

                  const contentEmail = document.querySelector(
                    `#open-tab-content-${buttonCenter.id}`
                  );
                  const tabName = this.querySelector(`div.name--js`);
                  const tabDate = this.querySelector(`div.date--js`);
                  const tabCompany = this.querySelector(`div.comapny--js`);

                  if (buttonCenter.classList.contains("rotate-180")) {
                    contentEmail.classList.add("max-h-[3000]");
                    this.classList.add("text-black");
                    this.classList.add("bg-focus");
                    this.classList.add("rounded-b-none");
                    this.classList.add("shadow-none");
                    tabName.classList.add("text-white");
                    tabDate.classList.add("text-white");
                    tabCompany.classList.add("text-white");
                    buttonCenter.classList.add("text-white");
                    currentOpenHeader = this;
                  } else {
                    contentEmail.classList.remove("max-h-[3000]");
                    this.classList.remove("text-black");
                    this.classList.remove("bg-focus");
                    this.classList.remove("rounded-b-none");
                    this.classList.remove("shadow-none");
                    tabName.classList.remove("text-white");
                    tabDate.classList.remove("text-white");
                    tabCompany.classList.remove("text-white");
                    buttonCenter.classList.remove("text-white");
                    currentOpenHeader = null;
                  }
                });
              });
            }
          }
          if (item.id.includes("maybelater") && item.id.includes(projectId)) {
            const leadsListMaybeLater = response.leads.filter(
              (lead) => lead.flag_maybelater && lead.id_project[0] === projectId
            );

            let currentOpenHeader = null;
            tempTabInfo.style.display = "none";

            if (leadsListMaybeLater.length !== 0) {
              tabsMain.style.display = "flex";
              sortingButton.style.visibility = "visible";

              leadsListMaybeLater.forEach((lead) => {
                leadsCounter++;
                const dataLead = new Date(lead.date);

                const dayLead = String(dataLead.getDate()).padStart(2, "0");
                const monthLead = String(dataLead.getMonth() + 1).padStart(
                  2,
                  "0"
                );
                const yearLead = dataLead.getFullYear();

                const dataLeadFormat = `${dayLead}/${monthLead}/${yearLead}`;

                const campaignName = response.campaigns.find(
                  (campaign) => campaign.id_campaign === lead.id_campaign[0]
                ).title;

                const campaignText =
                  lang === "pl" ? "Kampania: " : "Campaign: ";

                const leadElement = `<li id="lead-${
                  lead.id_lead + lead.id_campaign
                }" class="class="flex flex-col items-stretch text-sm" bg-white">
                                          <div class="rounded-2xl">
                                            <div class="flex flex-row justify-between items-center p-4 shadow-box-shadow rounded-2xl text-black focus:bg-focus gap-x-2 lead--js">
                                              <div class="w-3/12 text-sm name--js">${
                                                lead.sender_name
                                              }</div>
                                              <div class="w-3/12 text-sm date--js">${dataLeadFormat}</div>
                                              <div class="w-6/12 text-sm comapny--js">${
                                                lead.sender_company
                                              }</div>
                                              <button id="button-center-${
                                                lead.id_lead + lead.id_campaign
                                              }" class="h-5 flex justify-center items-center text-black origin-center rotate-0 transition ease-in-out duration-300">
                                                <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                                                  <path d="M7.41 18.41L6 17L12 11L18 17L16.59 18.41L12 13.83L7.41 18.41ZM7.41 12.41L6 11L12 5L18 11L16.59 12.41L12 7.83L7.41 12.41Z" fill="currentColor"></path>
                                                </svg>
                                              </button>
                                            </div>
                                            <div id="open-tab-content-button-center-${
                                              lead.id_lead + lead.id_campaign
                                            }" class="max-h-0 overflow-hidden border-none ease-in-out duration-300">
                                              <div class="p-4 shadow-box-shadow border border-solid rounded-b-xl email-content--js"></div>
                                              <div id="contacts-buttons-button-center-${
                                                lead.id_lead + lead.id_campaign
                                              }" class="mt-2 p-4 flex flex-row gap-x-3 items-center border-black border-b">
                                                <div class="max-w-[25%] p-4 overflow-hidden whitespace-nowrap text-ellipsis border borde-solid border-border-container rounded-lg">
                                                  <a href="mailto:${
                                                    lead.sender_email
                                                  }" title="${
                  lead.sender_email
                }" class="text-black text-sm no-underline">${
                  lead.sender_email
                }</a>
                                                </div>
                                                <div class="max-w-[25%] p-4 overflow-hidden whitespace-nowrap text-ellipsis border borde-solid border-border-container rounded-lg">
                                                  <span class="text-black text-sm no-underline" title="${
                                                    lead.sender_position
                                                  }">${
                  lead.sender_position ? lead.sender_position : "Pan/Pani"
                }</span>
                                                </div>
                                                <div class="max-w-[25%] p-4 overflow-hidden whitespace-nowrap text-ellipsis border borde-solid border-border-container rounded-lg ml-auto">
                                                  <span class="text-black text-sm no-underline" data-translate="campaign-text" title="Kampania: ${campaignName}">${campaignText}</span>
                                                  <span class="text-black text-sm no-underline font-bold" title="${campaignName}">${campaignName}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>`;

                const leadElementNode = stringToHTML(leadElement);
                tabsMain.appendChild(leadElementNode);

                const preElement = document.createElement("pre");
                preElement.className =
                  "text-xs word-break-all whitespace-pre-wrap break-words font-sans";
                preElement.innerHTML = lead.content;
                document
                  .querySelector(
                    `#open-tab-content-button-center-${
                      lead.id_lead + lead.id_campaign
                    } > .email-content--js`
                  )
                  .appendChild(preElement);

                const tabHead = document.querySelector(
                  `#lead-${
                    lead.id_lead + lead.id_campaign
                  } > div > div.lead--js`
                );
                tabHead.addEventListener("click", function () {
                  const buttonCenter = this.querySelector("button");

                  if (currentOpenHeader && currentOpenHeader !== this) {
                    const prevButton =
                      currentOpenHeader.querySelector("button");
                    prevButton.click();
                  }

                  buttonCenter.classList.toggle("rotate-180");

                  const contentEmail = document.querySelector(
                    `#open-tab-content-${buttonCenter.id}`
                  );
                  const tabName = this.querySelector(`div.name--js`);
                  const tabDate = this.querySelector(`div.date--js`);
                  const tabCompany = this.querySelector(`div.comapny--js`);

                  if (buttonCenter.classList.contains("rotate-180")) {
                    contentEmail.classList.add("max-h-[3000]");
                    this.classList.add("text-black");
                    this.classList.add("bg-focus");
                    this.classList.add("rounded-b-none");
                    this.classList.add("shadow-none");
                    tabName.classList.add("text-white");
                    tabDate.classList.add("text-white");
                    tabCompany.classList.add("text-white");
                    buttonCenter.classList.add("text-white");
                    currentOpenHeader = this;
                  } else {
                    contentEmail.classList.remove("max-h-[3000]");
                    this.classList.remove("text-black");
                    this.classList.remove("bg-focus");
                    this.classList.remove("rounded-b-none");
                    this.classList.remove("shadow-none");
                    tabName.classList.remove("text-white");
                    tabDate.classList.remove("text-white");
                    tabCompany.classList.remove("text-white");
                    buttonCenter.classList.remove("text-white");
                    currentOpenHeader = null;
                  }
                });
              });
            }
          }
          sortElements("date_desc");
          leadsCounterElement.style.visibility = "visible";
          globalLeadsCounter = leadsCounter;
          leadsCounterElement.innerText =
            lang == "pl"
              ? `Wyświetlono ${leadsCounter} z ${leadsCounter} wiadomości`
              : `Shown ${leadsCounter} of ${leadsCounter} messages`;
        });
      });
    }
  }
}

function sortElements(whichType) {
  const tabsMain = document.querySelector(
    "#w-node-_33dc3b9e-7c5d-9a0b-262f-2142c39b5972-1db0ed5f"
  );

  if (tabsMain.style.display === "flex") {
    const allLeads = tabsMain.querySelectorAll("li");
    const allLeadsArray = Array.from(allLeads);

    if (whichType === "name_desc" || whichType === "name_asc") {
      allLeadsArray.sort((a, b) => {
        const companyA = a.querySelector(".comapny--js").textContent.trim();
        const companyB = b.querySelector(".comapny--js").textContent.trim();

        const nameALastModified = removePolishChars(companyA);
        const nameBLastModified = removePolishChars(companyB);

        if (whichType === "name_asc") {
          if (nameALastModified < nameBLastModified) return -1;
          if (nameALastModified > nameBLastModified) return 1;

          return 0;
        } else {
          if (nameALastModified < nameBLastModified) return 1;
          if (nameALastModified > nameBLastModified) return -1;

          return 0;
        }
      });
    }
    if (whichType === "date_desc" || whichType === "date_asc") {
      allLeadsArray.sort((a, b) => {
        const convertToDate = (dateString) => {
          const [day, month, year] = dateString.split("/");
          return new Date(`${year}-${month}-${day}`);
        };

        const dateA = convertToDate(
          a.querySelector(".date--js").textContent.trim()
        );
        const dateB = convertToDate(
          b.querySelector(".date--js").textContent.trim()
        );

        if (whichType === "date_asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
    }
    allLeadsArray.forEach((li) => {
      tabsMain.appendChild(li);
    });
  }
}

function removePolishChars(str) {
  const polishMap = {
    ą: "a",
    ć: "c",
    ę: "e",
    ł: "l",
    ń: "n",
    ó: "o",
    ś: "s",
    ź: "z",
    ż: "z",
    Ą: "A",
    Ć: "C",
    Ę: "E",
    Ł: "L",
    Ń: "N",
    Ó: "O",
    Ś: "S",
    Ź: "Z",
    Ż: "Z",
  };

  return str
    .split("")
    .map((letter) => polishMap[letter] || letter)
    .join("");
}

function changeLanguage(lang) {
  document.querySelectorAll("[data-translate]").forEach((element) => {
    const key = element.getAttribute("data-translate");
    if (translations[lang] && translations[lang][key]) {
      element.textContent = translations[lang][key];
    }
  });
}
